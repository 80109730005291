<template>
    <div class="">
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
          <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" 
          :class="activeTab == 1 ? 'nav-link active':'nav-link'" data-toggle="tab">Triase</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" 
          :class="activeTab == 2 ? 'nav-link active':'nav-link'" data-toggle="tab">Dokter</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" 
          :class="activeTab == 3 ? 'nav-link active':'nav-link'" data-toggle="tab">Laboratorium</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" 
          :class="activeTab == 4 ? 'nav-link active':'nav-link'" data-toggle="tab">Radiologi</a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" 
          :class="activeTab == 5 ? 'nav-link active':'nav-link'" data-toggle="tab">Farmasi </a></li>
          <li class="nav-item"><a href="javascript:;" @click="changeTab(6)" 
          :class="activeTab == 6 ? 'nav-link active':'nav-link'" data-toggle="tab">Persetujuan Pasien</a>
          </li>
        </ul>    
        <div class="tab-content card-body p-0">
            <div :class="activeTab == 1 ? 'tab-pane fade show active':'tab-pane'" id="rrm-triage">
                <div class="row mt-2">
                    <div class="col-md-6 mb-2">
                    <label for="">Data Kajian</label>
                    <v-select @input="changeKajianTriase($event)" placeholder="Pilih Data Kajian" v-model="kajianTriase" :options="historiKajianTriase" label="text" :reduce="v=>v.value"></v-select>
                    </div>
                </div> 
                <template v-if="Object.keys(dataKajianTriase||{}).length && Object.keys(masterTriase||{}).length">
                    <table class="table table-bordered table-sm">
                        <tbody>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Triase Pasien</h4>
                                <p>{{getConfigDynamic(masterTriase.mUGDTriase,dataKajianTriase.row.aut_triase)||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Transportasi yang Digunakan</h4>
                                <p>{{getConfigDynamic(masterTriase.mUGDTransport,dataKajianTriase.row.aut_transportasi)||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Surat Rujukan</h4>
                                <p v-if="rowReg.aures_surat_rujukan"><a :href="$parent.$parent.$parent.uploader(rowReg.aures_surat_rujukan)">Lihat Surat Rujukan</a></p>
                                <p v-else> - </p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Nama Pengantar Pasien</h4>
                                <p>{{dataKajianTriase.row.aut_pengantar_pasien||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>No. Handphone</h4>
                                <p>{{dataKajianTriase.row.aut_no_hp||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Waktu Triase / Nama Penginput</h4>
                                <p>{{dataKajianTriase.row.aut_created_date | moment("DD MMMM YYYY, HH:mm")}} WIB
                                / {{dataKajianTriase.row.nama_inputter || "-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr class="table-secondary">
                            <th colspan="3">
                            <span class="font-weight-semibold">Keterangan Emergency Penjaminan</span>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="2">
                            <div class="result_tab">
                                <h4>Diagnosis</h4>
                                <p>{{dataKajianTriase.row.aut_diagnosa||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Pasien Mendapatkan Surat Emergency?</h4>
                                <p>{{dataKajianTriase.row.aut_is_surat_emergency == 'Y' ? "Ya" : "Tidak"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else>
                    <span class="text-center">Tidak Ada Kajian</span>
                </template>
            </div>
            <div :class="activeTab == 2 ? 'tab-pane fade show active':'tab-pane'" id="rrm-dokter">
                <template v-if="Object.keys(dataKajianDokter||{}).length && Object.keys(masterKajianDokter||{}).length">
                <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-0">
                    <li class="nav-item"><a href="javascript:;" @click="changeTabDokter(1)" 
                    :class="activeTabDokter == 1 ? 'nav-link active':'nav-link'" data-toggle="tab">Kajian</a></li>
                            <li class="nav-item"><a href="javascript:;" @click="changeTabDokter(2)" 
                    :class="activeTabDokter == 2 ? 'nav-link active':'nav-link'" data-toggle="tab">Catatan Asuhan</a></li>
                </ul>
                <div class="tab-content card-body p-0">
                    <div id="igdDokterKajian" :class="activeTabDokter == 1 ? 'tab-pane fade show active':'tab-pane'" >
                    
                        <div class="row">
                            <div class="col-md-6 mb-2">
                            <label for="">Data Kajian</label>
                            <v-select @input="changeKajianDokter($event)" placeholder="Pilih Data Kajian" v-model="dataKajianDokter.row.aurm_id" :options="historiKajianDokter" label="text" :reduce="v=>v.value"></v-select>
                            </div>
                        </div> 

                        <div class="card">
                            <div class="card-header bg_head_panel">
                            <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                            </div>
                            <div class="card-body p-3">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{row.ap_code||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{row.ap_fullname||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir</h4>
                                        <p>{{row.pob||"-"}}, {{row.dob | moment("DD MMMM YYYY") }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{row.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Dokter Penginput</h4>
                                        <p>{{dataKajianDokter.row.nama_dokter||"-"}}</p>
                                        </div>
                                    </td>
                                    
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Subjective</h5>
                            </div>
                            <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Keluhan Utama</h4>
                                        <p>{{dataKajianDokter.row.ausd_keluhan||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Riwayat Penyakit</h4>
                                        <p>{{dataKajianDokter.row.ausd_riwayat||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td colspan="2">
                                        <div class="result_tab">
                                        <h4>Catatan Dokter</h4>
                                        <p>{{dataKajianDokter.row.ausd_catatan_dokter||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <div class="form-row mt-2">
                                <div class="col-md-6">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Alergi</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="form-row" v-if="dataKajianDokter.row.ausd_has_obat_rutin == 'Y'">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <tbody>
                                                        <tr v-for="(v,k) in (dataKajianDokter.row.ausd_alergi||[])" :key="k+'ob'">
                                                            <td>
                                                                <div class="result_tab">
                                                                {{v.jenis||"-"}}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="result_tab">
                                                                {{v.name||"-"}}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="form-row" v-else>
                                                <div class="col-md-12">
                                                    <span>Tidak Ada Obat Rutin</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="form-row" v-if="dataKajianDokter.row.ausd_has_obat_rutin == 'Y'">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                <tr>
                                                    <td>
                                                    <div class="result_tab">
                                                        <h4>Keterangan</h4>
                                                        <p>{{dataKajianDokter.row.ausd_keterangan_obat_rutin||"-"}}</p>
                                                    </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="form-row" v-else>
                                            <div class="col-md-12">
                                                <span>Tidak Ada Obat Rutin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Objective</h5>
                            </div>
                            <div class="card-body">
                            <div>
                                <div class="result_tab mb-2">
                                <h4>Keadaan Umum</h4>
                                <p>{{dataKajianDokter.row.auod_keadaan_umum||"-"}}</p>
                                </div>
                                <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Tekanan Darah</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_tekanan_darah_min || dataKajianDokter.row.auod_ttv_tekanan_darah_max">{{dataKajianDokter.row.auod_ttv_tekanan_darah_min}} / {{dataKajianDokter.row.auod_ttv_tekanan_darah_max}} mmHG</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Nadi</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_nadi">{{dataKajianDokter.row.auod_ttv_nadi||"-"}}/mnt</p>
                                                <p v-else> - </p>
                                                
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Gula Darah</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_gula_darah">{{dataKajianDokter.row.auod_ttv_gula_darah||"-"}}mg/dL</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Pernafasan</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_pernafasan">{{dataKajianDokter.row.auod_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>SPO2</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_spo2">{{dataKajianDokter.row.auod_ttv_spo2||"-"}}%</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Suhu</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_suhu">{{dataKajianDokter.row.auod_ttv_suhu||"-"}}C</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Berat Badan</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_weight">{{dataKajianDokter.row.auod_ttv_weight||"-"}}kg</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Tinggi</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_height">{{dataKajianDokter.row.auod_ttv_height||"-"}}Cm</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Lingkar Kepala</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_lingkar_kepala">{{dataKajianDokter.row.auod_ttv_lingkar_kepala||"-"}}cm</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>BMI</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_bmi">{{dataKajianDokter.row.auod_ttv_bmi}}m2</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td v-if="dataKajianDokter.row.ap_usia <= 15">
                                                <div class="result_tab">
                                                <h4>Luas Permukaan Tubuh Anak</h4>
                                                <p v-if="dataKajianDokter.row.auod_ttv_luas_permukaan_anak">{{dataKajianDokter.row.auod_ttv_luas_permukaan_anak}}kg/m2</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <!--
                                            <td>
                                                <div class="result_tab">
                                                <h4>Kesadaran</h4>
                                                <p>{{getConfigDynamic(masterKajianDokter.mKesadaran,dataKajianDokter.row.auod_ttv_kesadaran)||"-"}}</p>
                                                </div>
                                            </td>
                                            -->
                                            <td colspan="2">
                                                <div class="result_tab">
                                                <h4>IMT</h4>
                                                <p>{{dataKajianDokter.row.auod_ttv_imt||"-"}}</p>
                                                </div>
                                            </td>
                                            
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                                <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Kesadaran GCS</h6>
                                </div>
                                <div class="card-body p-3">
                                    <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Respons Mata</h4>
                                            <p>{{getConfigDynamic(Config.mr.ksResponseMata,dataKajianDokter.row.auod_response_mata)||"-"}}<span class="font-weight-semibold text-info">({{dataKajianDokter.row.auod_response_mata||0}})</span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Respons Verbal</h4>
                                            <p>{{getConfigDynamic(Config.mr.ksResponseVerbal,dataKajianDokter.row.auod_response_verbal)||"-"}}<span class="font-weight-semibold text-info">({{dataKajianDokter.row.auod_response_verbal||0}})</span></p>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Respons Motorik</h4>
                                            <p>{{getConfigDynamic(Config.mr.ksResponseMotorik,dataKajianDokter.row.auod_response_motorik)||"-"}}<span class="font-weight-semibold text-info">({{dataKajianDokter.row.auod_response_motorik||0}})</span></p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td colspan="3">
                                            <div class="result_tab">
                                            <h4>Nilai GCS</h4>
                                            <p>{{dataKajianDokter.row.auod_gcs_total||0}} - 
                                            <span v-if="dataKajianDokter.row.auod_gcs_total >= 14"> Composmentis</span> 
                                            <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 12 && dataKajianDokter.row.auod_gcs_total <= 13">Apatis</span>
                                            <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 10 && dataKajianDokter.row.auod_gcs_total <= 11">Delirium</span>
                                            <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 7 && dataKajianDokter.row.auod_gcs_total <= 9">Somonolen</span>
                                            <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 5 && dataKajianDokter.row.auod_gcs_total <= 6">Sopor</span>
                                            <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 4 && dataKajianDokter.row.auod_gcs_total <= 4">Semi-Comma</span>
                                            <span v-else>Coma</span>
                                            </p>
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                                
                                <div class="card">
                                
                                <template v-if="row.ap_usia > 6 || (row.ap_usia == null && row.ap_dob == null && dataKajianDokter.row.auod_nyeri_type == 'NP')">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Numeric Pain Rating Scale</h6>
                                    </div>
                                    
                                    <div class="card-body p-3">
                                        <div class="form-group">
                                        <label for="">Skala Nyeri</label>
                                        <div class="pain-scale no-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value == 0">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Tidak Ada Nyeri</strong>
                                        </div>
                                        <div class="pain-scale mild-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value >= 1 && dataKajianDokter.row.auod_skala_nyeri_value <= 3">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Sedikit Nyeri</strong>
                                        </div>
                                        <div class="pain-scale moderate-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value >= 4 && dataKajianDokter.row.auod_skala_nyeri_value <= 5">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Agak Mengganggu</strong>
                                        </div>
                                        <div class="pain-scale severe-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value >= 6 && dataKajianDokter.row.auod_skala_nyeri_value <= 7">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Mengganggu Aktivitas</strong>
                                        </div>
                                        
                                        <div class="pain-scale worst-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value >= 8 && dataKajianDokter.row.auod_skala_nyeri_value <= 9">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Sangat Mengganggu</strong>
                                        </div>
                                        <div class="pain-scale worst-pain" v-if="dataKajianDokter.row.auod_skala_nyeri_value >= 10">
                                            <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                                            <strong>Tak Tertahankan</strong>
                                        </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="(row.ap_usia <= 6 && row.ap_usia >= 1) || (row.ap_usia == null && row.ap_dob == null && dataKajianDokter.row.auod_nyeri_type == 'FL')">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">FLACC Scale</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="form-group">
                                            <label for="kidPainScale">Skala Nyeri</label>
                                            <table class="table table-bordered pain-scale-table">
                                            <thead>
                                                <tr>
                                                <th>Pengkajian</th>
                                                <th>Hasil</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(v,k) in dataKajianDokter.row.auod_skala_nyeri" :key="k+'akn'">
                                                    <th class="table-warning text-center" style="width: 30%;"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                                        style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                                        {{v.name}}</th>
                                                    <td class="p-2">
                                                        {{getConfigDynamic(v.configVal,v.value)||"-"}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr class="table-info">
                                                <td colspan="4" class="text-info-700">
                                                    <span>NILAI SKALA NYERI: {{dataKajianDokter.row.auod_skala_nyeri_value||"-"}}</span>
                                                    <span v-if="dataKajianDokter.row.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                                                    <span v-else-if="dataKajianDokter.row.auod_skala_nyeri_value >= 1 && dataKajianDokter.row.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                                                    <span v-else-if="dataKajianDokter.row.auod_skala_nyeri_value >= 4 && dataKajianDokter.row.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                                    <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                                                </td>
                                                </tr>
                                            </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="row.ap_usia < 1 || (row.ap_usia == null && row.ap_dob == null && dataKajianDokter.row.auod_nyeri_type == 'NIPS')">
                                    <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Neonatal-Infant Pain Scale (NIPS)</h6>
                                    </div>
                                    <div class="card-body p-3">
                                    <div class="form-group">
                                        <label for="kidPainScale">Skala Nyeri</label>
                                        <table class="table table-bordered pain-scale-table">
                                        <thead>
                                            <tr>
                                            <th>Pengkajian</th>
                                            <th>Hasil</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(v,k) in dataKajianDokter.row.auod_skala_nyeri_nips" :key="k+'akn'">
                                                <th class="table-warning text-center" style="width: 30%;"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                                                    style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                                                    {{v.name}}</th>
                                                <td class="p-2">
                                                    {{getConfigDynamic(v.configVal,v.value)||"-"}}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="table-info">
                                            <td colspan="4" class="text-info-700">
                                                <span>NILAI SKALA NYERI: {{dataKajianDokter.row.auod_skala_nyeri_value||0}}</span>
                                                
                                                <span v-if="dataKajianDokter.row.auod_skala_nyeri_value < 3" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Ringan, Tidak Nyeri</span>
                                                <span v-else-if="dataKajianDokter.row.auod_skala_nyeri_value >= 3 && dataKajianDokter.row.auod_skala_nyeri_value < 5" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang - Nyeri Ringan (intervensi tanpa obat, dievaluasi selama 30 menit)</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Hebat (intervensi tanpa obat, bila masih nyeri diberikan analgesik dan dievaluasi selama 30 menit)</span>
                                            </td>
                                            </tr>
                                        </tfoot>
                                        </table>
                                    </div>
                                    </div>
                                </template>

                                </div>
                                

                                <div class="card">
                                    <div class="card-header bg_head_panel" @click="keadaanUmum = !keadaanUmum">
                                    <h6 class="card-title font-weight-semibold" 
                                    >Keadaan Umum</h6>
                                    </div>
                                    <div class="card-body p-3" v-if="keadaanUmum">
                                    <table class="table table-bordered">
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kepala</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_kepala == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_kepala_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Mata</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_mata == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_mata_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Hidung</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_hidung == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_hidung_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Rambut</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_rambut == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_rambut_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Bibir</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_bibir == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_bibir_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Gigi Geligi</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_gigi == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_gigi_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Lidah</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_lidah == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_lidah_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Langit-Langit</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_langit == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_langit_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Leher</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_leher == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_leher_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tenggorokan</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_tenggorokan_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tonsil</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_tonsil_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Dada</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_dada == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_dada_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Payudara</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_payudara == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_payudara_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Punggung</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_punggung == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_punggung_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Perut</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_perut == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_perut_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Genital</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_genital == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_genital_text||"-"}}</p>
                                            </div>
                                        </td>
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Anus</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_anus == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_anus_text||"-"}}</p>
                                            </div>
                                        </td>
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Lengan Atas</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_lengan_atas_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Lengan Bawah</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_lengan_bawah_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Jari Tangan</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_jari_tangan_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kuku Tangan</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_kuku == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_kuku_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Sendi Tangan</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_sendi_tangan_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tungkai Atas</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_tungkai_atas_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Tungkai Bawah</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_tungkai_bawah_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Jari Kaki</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_jari_kaki_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Kuku Kaki</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_kuku_kaki_text||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Sendi Kaki</h4>
                                            <p v-if="dataKajianDokter.row.auod_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                            <p v-else-if="dataKajianDokter.row.auod_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                                            <p v-else>{{dataKajianDokter.row.auod_vs_persendian_kaki_text||"-"}}</p>
                                            </div>
                                        </td>
                                        </tr>
                                        <tr>
                                            <td width="33%" colspan="3">
                                                <div class="result_tab">
                                                <h4>Saraf</h4>
                                                <p v-if="dataKajianDokter.row.auod_vs_saraf == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                <p v-else-if="dataKajianDokter.row.auod_vs_saraf == 'TP'">Tidak Diperiksa</p>
                                                <p v-else>
                                                    <table class="table table-bordered">
                                                        <tr>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Refleks Fisiologis</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_r_fisiologis||"-"}}</p>
                                                                </div>
                                                            </td>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Refleks Patologis</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_r_patologis||"-"}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Babinski</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_babinski||"-"}}</p>
                                                                </div>
                                                            </td>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Lasegue</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_lasegue||"-"}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Bregard</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_bregard||"-"}}</p>
                                                                </div>
                                                            </td>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Sicard</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_sicard||"-"}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Kaku Duduk</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_kaku_duduk||"-"}}</p>
                                                                </div>
                                                            </td>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Kernig Sign</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_kernig_sign||"-"}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Brudzinski 1</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_brudzinski||"-"}}</p>
                                                                </div>
                                                            </td>
                                                            <td width="50%">
                                                                <div class="result_tab">
                                                                <h4>Brudzinski 2</h4>
                                                                <p>{{dataKajianDokter.row.auod_vs_saraf_brudzinski2||"-"}}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    </div>
                                </div>

                                <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Kajian Primer</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="mb-2">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colspan="2">Airways</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Kondisi</h4>
                                                <p>{{getConfigDynamic(Config.mr.bebasTidakOpt,dataKajianDokter.row.auod_airways_val)||"-"}} - {{airwaysVal}}</p>
                                                
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Suara Nafas</h4>
                                                <p v-if="dataKajianDokter.row.auod_airways_suara_napas != 'L'">{{dataKajianDokter.row.auod_airways_suara_napas||'-'}}</p>
                                                <p v-else>{{dataKajianDokter.row.auod_airways_suara_napas_text||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div class="mb-2">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colspan="2">Breathing</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Kondisi</h4>
                                                <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,dataKajianDokter.row.auod_breathing_val)||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Pola Nafas</h4>
                                                <p>{{getConfigDynamic(Config.mr.polaNapasOpt,dataKajianDokter.row.auod_breathing_pola_napas)||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Frekuensi Nafas</h4>
                                                <p v-if="dataKajianDokter.row.auod_breathing_frekuensi && dataKajianDokter.row.auod_breathing_label">
                                                {{dataKajianDokter.row.auod_breathing_frekuensi||0}} x/mnt - {{dataKajianDokter.row.auod_breathing_label}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Bunyi Nafas</h4>
                                                <p>{{dataKajianDokter.row.auod_breathing_bunyi_nafas||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Tanda Distress Pernafasan</h4>
                                                <p>{{dataKajianDokter.row.auod_breathing_tanda_napas||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Jenis Pernafasan</h4>
                                                <p>{{dataKajianDokter.row.auod_breathing_jenis_nafas||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div class="mb-2">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colspan="2">Circulation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Kondisi</h4>
                                                <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,dataKajianDokter.row.auod_circulation_val)||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Ketidak Normalan</h4>
                                                <p class="mb-1">{{circulationVal||"-"}}</p>

                                                <template  v-if="dataKajianDokter.row.auod_circulation_pendarahan_text">
                                                    <h4>Pendarahan</h4>
                                                    <p class="mb-1">{{dataKajianDokter.row.auod_circulation_pendarahan_text||"-"}}
                                                    </p>
                                                </template>
                                                
                                                <template  v-if="dataKajianDokter.row.auod_circulation_lainnya_text">
                                                    <h4>Lain Lain</h4>
                                                    <p class="mb-1">{{dataKajianDokter.row.auod_circulation_lainnya_text||"-"}}
                                                    </p>
                                                </template>
                                                
                                                <template  v-if="dataKajianDokter.row.auod_circulation_pengisian_kapiler">
                                                    <h4>Pengisian Kapiler</h4>
                                                    <p class="mb-1">{{dataKajianDokter.row.auod_circulation_pengisian_kapiler == 'Y' ?
                                                    'YA' : 'Tidak'}}
                                                    </p>
                                                </template>
                                                
                                                <template  v-if="dataKajianDokter.row.auod_circulation_nadi_text">
                                                    <h4>Nadi</h4>
                                                    <p class="mb-1">{{dataKajianDokter.row.auod_circulation_nadi_text||"-"}} - 
                                                    {{dataKajianDokter.row.auod_circulation_nadi_label == "R" ? "Reguler" : "Irreguler"}} - {{dataKajianDokter.row.auod_circulation_nadi_status}}
                                                    </p>
                                                </template>

                                                
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div class="mb-2">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colspan="2">Disability</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <div class="result_tab">
                                                    <h4>Kondisi</h4>
                                                    <p>{{getConfigDynamic(Config.mr.normalTidakNormalOpt,dataKajianDokter.row.auod_disability_val)||"-"}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                <div class="result_tab">
                                                    <h4>Disability Tidak Normal</h4>
                                                    <p>{{getConfigDynamic(Config.mr.disabilty,dataKajianDokter.row.auod_disability_tidak_normal)||"-"}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="table-secondary">
                                            <th colspan="2">Kekuatan Otot</th>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Tangan Kanan</h4>
                                                <p>{{dataKajianDokter.row.auod_disability_tangan_kanan||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Tangan Kiri</h4>
                                                <p>{{dataKajianDokter.row.auod_disability_tangan_kiri||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Kaki Kanan</h4>
                                                <p>{{dataKajianDokter.row.auod_disability_kaki_kanan||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Kaki Kiri</h4>
                                                <p>{{dataKajianDokter.row.auod_disability_kaki_kiri||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                    <div>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th colspan="2">Environment</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Adanya trauma pada daerah</h4>
                                                <p>{{dataKajianDokter.row.auod_env_trauma||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Adanya jejas / luka pada daerah</h4>
                                                <p>{{dataKajianDokter.row.auod_env_jelas_luka||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Ukuran luka</h4>
                                                <p>{{dataKajianDokter.row.auod_env_ukuran_luka||"-"}}</p>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="result_tab">
                                                <h4>Kedalaman luka</h4>
                                                <p>{{dataKajianDokter.row.auod_env_kedalaman_luka||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                            <div class="result_tab">
                                                <h4>Lain-lain</h4>
                                                <p>{{dataKajianDokter.row.auod_env_lainnya||"-"}}</p>
                                            </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                                <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                                </div>
                                
                                <div class="card-body p-3">
                                    <div class="row gx-2">
                                        <div class="col-lg-4">
                                        <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                        <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in dataKajianDokter.row.auod_lokasi_nyeri||[]" :key="k+'nav'">
                                            <a href="javascript:;" data-toggle="tab" 
                                            :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <span>{{v.name||"(Anatomi Baru)"}}</span>
                                                <div>
                                                <i class="icon-chevron-right"></i>
                                                </div>
                                            </div>
                                            </a>
                                        </li>
                                        </ul>
                                        </div>
                                        <div class="col-lg-8">
                                        <div class="tab-content">
                                            <div v-for="(v,k) in dataKajianDokter.row.auod_lokasi_nyeri||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                            <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                                <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                                                <div class="body_wrapper">
                                                    <img :src="$parent.$parent.$parent.assetLocal(v.image)" class="img-fluid w-100" alt="">
                                                </div>
                                                <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                                                    :style="`top:${v1.y}%;left:${v1.x}%`">
                                                    <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                                    :title="v1.value"></a>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Assessment</h5>
                            </div>
                                
                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataKajianDokter.dataICD10" :key="k+'diagnosaDok2'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi10_code">
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.auicd10d_diagnosa||"-"}}</p>
                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.text||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Diagnosa Lainnya</h4>
                                            <p>{{dataKajianDokter.row.auod_diagnosa||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>

                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataKajianDokter.dataICD9" :key="k+'diagnosaDok'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi9_code">
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.auicd9d_tindakan||"-"}}</p>
                                        
                                        <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.auicd9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>

                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.text||"-"}}</p>
                                        
                                        <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.auicd9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>

                                        </div>

                                        
                                    </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="result_tab">
                                            <h4>Tindakan Lainnya</h4>
                                            <p>{{dataKajianDokter.row.auod_tindakan||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Planning</h5>
                            </div>
                            <div class="card-body">
                            
                            <div class="card" v-if="dataKajianDokter.row.aupdl_is_lab == 'Y'">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <thead>
                                    <tr class="table-light">
                                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Prioritas Pemeriksaan</h4>
                                          <p v-if="dataKajianDokter.row.aupdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                          <p v-else>Non CITO</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Pasien Sedang Berpuasa?</h4>
                                          <p>{{dataKajianDokter.row.aupdl_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="req-list-resume">
                                  <div class="row g-3">
                                    <template v-for="(v,k) in dataKajianDokter.row.aupdl_data">
                                      <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                        <div class="card shadow-0 border">
                                          <div class="card-header py-2 bg-light">
                                            <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                          </div>
                                          <div class="card-body py-2">
                                            <template v-for="v1,k1 in (v.dubData||[])" >
                                              <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                                                <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                                <template v-for="v2,k2 in (v1.data||[])">
                                                  <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                    <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                                    <span class="align-middle" v-if="v2.notes">, {{v2.notes}}</span>
                                                  </div>
                                                </template>
                                              </div>
                                            </template>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                    <div class="col-md-4 col-lg-3" v-if="dataKajianDokter.row.aupdl_hasil_lainnya">
                                      <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                          <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                        </div>
                                        <div class="card-body py-2">
                                            <div class="req-list">
                                                <div>
                                                  <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                  <span class="align-middle">{{dataKajianDokter.row.aupdl_hasil_lainnya||"-"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                  </div>
                                </div>
                              </div>
                              <div class="card-body p-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                      <thead>
                                        <tr>
                                          <th>Hasil Pemeriksaan </th>
                                          <th>Hasil</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (dataKajianDokter.row.aupdl_hasil||[])" :key="k+'hasil'">
                                          <td>{{v.value}}</td>
                                          <td>
                                              <div class="result_tab form-group mb-0">
                                              <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                              </div>
                                          </td>
                                        </tr>
                                        <tr v-if="!(dataKajianDokter.row.aupdl_hasil||[]).length">
                                          <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card" v-if="dataKajianDokter.row.aupdr_is_radio == 'Y'">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Radiologi</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <thead>
                                    <tr class="table-light">
                                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Prioritas Pemeriksaan</h4>
                                          <p v-if="dataKajianDokter.row.aupdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                          <p v-else>Non CITO</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                                          <p>{{dataKajianDokter.row.aupdr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="row g-3">
                                  <template v-for="(v,k) in dataKajianDokter.row.aupdr_data">
                                    <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                                      <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                          <h6 class="card-title text-uppercase font-weight-semibold">{{
                                            getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                        </div>
                                        <div class="card-body py-2">
                                          <template v-for="v1,k1 in (v.data||[])">
                                            <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                <span class="align-middle font-weight-semibold">{{v1.text||"-"}}
                                                <span class="align-middle" v-if="v1.notes">, {{v1.notes}}</span>
                                                </span>
                                              
                                                <a href="javascript:;" @click="openInformConsentDetailRadiologi(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                                <i class="icon-file-eye2"></i>
                                                </a>
                                            </div>
                                          </template>
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="col-md-4 col-lg-3" v-if="dataKajianDokter.row.aupdr_hasil_lainnya">
                                    <div class="card shadow-0 border">
                                      <div class="card-header py-2 bg-light">
                                        <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                      </div>
                                      <div class="card-body py-2">
                                          <div class="req-list">
                                              <div>
                                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                <span class="align-middle">{{dataKajianDokter.row.aupdr_hasil_lainnya||"-"}}</span>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                </div>
                              </div>
                              <div class="card-body p-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                      <thead>
                                        <tr>
                                          <th>Hasil Pemeriksaan </th>
                                          <th>Hasil</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (dataKajianDokter.row.aupdr_hasil||[])" :key="k+'hasil'">
                                          <td>{{v.value}}</td>
                                          <td>
                                              <div class="result_tab form-group mb-0">
                                              <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                              </div>
                                          </td>
                                        </tr>
                                        <tr v-if="!(dataKajianDokter.row.aupdr_hasil||[]).length">
                                          <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="mb-3">
                                <table class="table table-bordered">
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Rencana Tindakan Pengobatan</h4>
                                        <p>{{dataKajianDokter.row.aupd_rencana_pengobatan||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </table>
                            </div>
                            <div class="card">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Resep</h6>
                                </div>
                                <div class="card-body p-3">
                                <table class="table table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th>Jenis</th>
                                            <th>Nama</th>
                                            <th>Jumlah</th>
                                            <th>Dosis</th>
                                            <th>Keterangan</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(v,k) in (dataKajianDokter.resepDokter||[])" :key="'resepDokter'+k">
                                            <td>
                                                <div class="result_tab">
                                                <p>{{v.aurd_jenis == 1 ? 'Obat' : v.aurd_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <p v-if="v.aurd_item_id == 99999">
                                                {{v.aurd_item_lainnya||"-"}}
                                                </p>
                                                <p v-else>
                                                {{v.aurd_nama||"-"}}
                                                </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <p>{{v.aurd_jumlah||"-"}} {{v.aurd_satuan||"-"}}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <p>
                                                    <span v-if="v.aurd_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                                    <span v-else>{{v.aurd_frekuensi_lainnya || "-"}}</span>
                                                </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <p>{{v.aurd_keterangan || "-"}}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!(dataKajianDokter.resepDokter||[]).length" class="table-info">
                                            <td class="text-center" colspan="99">Tidak Ada Data</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                                </div>
                                
                                <div class="card-body p-3">
                                <table class="table table-bordered" v-if="dataKajianDokter.row.aupd_tindak_lanjut.indexOf('RU') != -1">
                                    <thead>
                                    <tr class="table-secondary">
                                        <th colspan="2">Tindak Lanjut</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Tindak Lanjut</h4>
                                            <p>Rujukan</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Alasan Merujuk</h4>
                                            <p>{{getConfigDynamic(Config.mr.alasanRujuk,dataKajianDokter.row.aupd_alasan_rujuk)||"-"}} - {{dataKajianDokter.row.aupd_alasan_rujuk_text||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Faskes Rujukan</h4>
                                            <p>{{dataKajianDokter.row.aupd_faskes_rujuk_sisrute_text||"-"}}</p>
                                        </div>
                                        </td>
                                        
                                        <td>
                                        <div class="result_tab">
                                            <h4>Peralatan Medis yang terpasang</h4>
                                            <p v-if="dataKajianDokter.row.aupd_is_media == 'Y'">
                                                <ul>
                                                    <li v-for="(vq,kq) in (dataKajianDokter.row.aupd_media||[])" :key="kq">
                                                        {{vq}}
                                                    </li>
                                                </ul>
                                            </p>
                                            <p v-else> - </p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Perawatan yang pasien butuhkan</h4>
                                            <p>{{dataKajianDokter.row.aupd_perawatan_pasien||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                                <table class="table table-bordered" v-if="dataKajianDokter.row.aupd_tindak_lanjut.indexOf('RJ') != -1">
                                    <thead>
                                    <tr class="table-secondary">
                                        <th colspan="2">Tindak Lanjut</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Tindak Lanjut Rawat Jalan</h4>
                                            <p>{{getConfigDynamic(Config.mr.tindakLanjutRawatJalan,dataKajianDokter.row.aupd_rawat_jalan_tindakan)||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                                <table class="table table-bordered" v-if="dataKajianDokter.row.aupd_tindak_lanjut.indexOf('RI') != -1">
                                    <thead>
                                    <tr class="table-secondary">
                                        <th colspan="2">Tindak Lanjut</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td colspan="2">
                                        <div class="result_tab">
                                            <h4>Tindak Lanjut</h4>
                                            <p>Rawat Inap</p>
                                        </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Ruangan</h4>
                                            <p>{{getConfigDynamic(masterKajianDokter.mRuangan,dataKajianDokter.row.aupd_rawat_inap_ruangan)||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Nama DPJP</h4>
                                            <p>{{getConfigDynamic(masterKajianDokter.mDokterAll, dataKajianDokter.row.aupd_dokter_ranap)||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                                <span v-if="!(dataKajianDokter.row.aupd_tindak_lanjut||[]).length">Tidak ada Tindak Lanjut</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div id="igdDokterAsuhan" :class="activeTabDokter == 2 ? 'tab-pane fade show active':'tab-pane'" >
                        <div class="table-responsive mb-0">
                            <table class="table table-bordered table-sm table-align-top">
                            <thead>
                                <tr>
                                <th>Waktu</th>
                                <th>Asesmen/Tindakan</th>
                                <th>Waktu Evaluasi</th>
                                <th>Evaluasi</th>
                                <th>PPA</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <template v-if="dataKajianDokter.row.aupd_laporan_dpjp == 'Y'">
                                <tr class="dpjpEl">
                                    <td>
                                        <span>{{dataKajianDokter.row.aupd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                                        {{dataKajianDokter.row.aupd_laporan_dpjp_waktu||"-"}}</span>
                                    </td>
                                    
                                    <td>
                                        <div class="tb-content">
                                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                        <p><span class="font-weight-semibold">S</span>: {{dataKajianDokter.row.ausd_keluhan||"-"}} <span v-if="dataKajianDokter.row.ausd_riwayat">, Pasien mempunyai riwayat penyakit: {{dataKajianDokter.row.ausd_riwayat||"-"}} </span></p>
                                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                                            <ul class="mb-0" v-if="dataKajianDokter.row.ausd_has_alergi == 'Y'">
                                                <li v-for="(v,k) in (dataKajianDokter.row.ausd_alergi||[])" :key="k"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v.jenis)}})</span>{{v.name||"-"}}</li>
                                            </ul>
                                        </p>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                                            <div>{{dataKajianDokter.row.ausd_catatan_dokter||"-"}}</div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                            <div>
                                            <span>{{dataKajianDokter.row.aupd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tindakan Invasif</span>
                                            <template v-if="(dataKajianDokter.row.aupd_laporan_dpjp_tindakan_invasif||[]).length">
                                            <ol class="mb-0"
                                            v-for="(v,k) in (dataKajianDokter.row.aupd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                            <div><span>{{dataKajianDokter.row.aupd_laporan_dpjp_keluhan||"-"}}</span></div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                                            <div>{{dataKajianDokter.row.auod_skala_nyeri_value}} | 
                                            
                                                <span v-if="dataKajianDokter.row.auod_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                                                <span v-else-if="dataKajianDokter.row.auod_skala_nyeri_value >= 1 && dataKajianDokter.row.auod_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                                                <span v-else-if="dataKajianDokter.row.auod_skala_nyeri_value >= 4 && dataKajianDokter.row.auod_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                                                
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                            <ul class="mb-0">
                                            
                                            <li>Tekanan Darah: {{dataKajianDokter.row.auod_ttv_tekanan_darah_min||0}}/{{dataKajianDokter.row.auod_ttv_tekanan_darah_max||0}} mmHG</li>
                                            <li>Nadi: {{dataKajianDokter.row.auod_ttv_nadi||0}} x/mnt - {{dataKajianDokter.row.auod_ttv_label||"-"}}</li>
                                            <li>Gula Darah: {{dataKajianDokter.row.auod_ttv_gula_darah||0}} mg/dL</li>
                                            <li>Pernafasan: {{dataKajianDokter.row.auod_ttv_pernafasan||0}} x/mnt</li>
                                            <li>SPO2: {{dataKajianDokter.row.auod_ttv_spo2||0}}%</li>
                                            <li>Suhu: {{dataKajianDokter.row.auod_ttv_suhu||0}}C</li>
                                            <li>Berat Badan {{dataKajianDokter.row.auod_ttv_weight||0}} kg</li>
                                            <li>Tinggi: {{dataKajianDokter.row.auod_ttv_height||0}} cm</li>
                                            <li>Lingkar Kepala: {{dataKajianDokter.row.auod_ttv_lingkar_kepala||0}} cm</li>
                                            <li>Luas Permukaan Tubuh Anak: {{dataKajianDokter.row.auod_ttv_luas_permukaan_anak||0}} M<sup>2</sup></li>
                                            <li>Kesadaran: Hanya merespons jika dirangsang nyeri/pain: 
                                            {{dataKajianDokter.row.auod_gcs_total||0}}</li>

                                            </ul>
                                        </div>
                                        <div class="mb-2">
                                            <span>{{dataKajianDokter.row.auod_gcs_total}} | 
                                        
                                                <span v-if="dataKajianDokter.row.auod_gcs_total >= 14"> Composmentis</span> 
                                                <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 12 && dataKajianDokter.row.auod_gcs_total <= 13">Apatis</span>
                                                <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 10 && dataKajianDokter.row.auod_gcs_total <= 11">Delirium</span>
                                                <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 7 && dataKajianDokter.row.auod_gcs_total <= 9">Somonolen</span>
                                                <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 5 && dataKajianDokter.row.auod_gcs_total <= 6">Sopor</span>
                                                <span v-else-if="dataKajianDokter.row.auod_gcs_total >= 4 && dataKajianDokter.row.auod_gcs_total <= 4">Semi-Comma</span>
                                                <span v-else>Coma</span>
                                                
                                            </span>
                                            
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Lab</span>
                                            <template v-if="(dataKajianDokter.row.aupdl_hasil||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (dataKajianDokter.row.aupdl_hasil||[])" :key="k">
                                                <li>{{v.value}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Radiologi</span>
                                            <template v-if="(dataKajianDokter.row.aupdr_hasil||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (dataKajianDokter.row.aupdr_hasil||[])" :key="k">
                                                <li>{{v.value}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">R</span>
                                            <div class="mb-1">
                                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                            <template v-if="(dataKajianDokter.row.aupd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (dataKajianDokter.row.aupd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                            

                                            </div>
                                            <div class="mb-1">
                                            <span>Usulan kolaborasi</span>
                                            <template v-if="(dataKajianDokter.row.aupd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (dataKajianDokter.row.aupd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>

                                            </div>
                                        </div>
                                        </div>
                                    </td>
                                    
                                    <td>
                                        <span>{{dataKajianDokter.row.aupd_laporan_dpjp_tanggal_evaluasi}} {{dataKajianDokter.row.aupd_laporan_dpjp_waktu_evaluasi}}</span>
                                    </td>
                                    
                                    <td>
                                        <div class="form-group">
                                        <span>{{dataKajianDokter.row.aupd_laporan_dpjp_evaluasi||"-"}}</span>
                                        </div>
                                        <span>Data sudah diverifikasi oleh 
                                        {{getConfigDynamic(masterKajianDokter.mDokterAll,dataKajianDokter.row.aupd_laporan_dpjp_nama)}} pada 
                                        {{dataKajianDokter.row.aupd_laporan_dpjp_evaluasi_date | moment("DD MMMM YYYY")}} {{dataKajianDokter.row.aupd_laporan_dpjp_evaluasi_waktu}}</span>
                                    </td>
                                    
                                    <td>
                                        <span>{{dataKajianDokter.row.aupd_laporan_dpjp_ppa||"-"}}</span>
                                    </td>
                                </tr>
                                
                                <tr class="dpjpEl" v-for="(v,k) in (dataKajianDokter.catatanDPJPDokter||[])" :key="k">
                                    <td>
                                        <span>{{v.auldd_laporan_dpjp_tanggal|moment("DD MMMM YYYY")}} 
                                        {{v.auldd_laporan_dpjp_waktu||"-"}}</span>
                                    </td>
                                    
                                    <td>
                                        <div class="tb-content">
                                        <p class="font-weight-semibold text-blue-800">Melaporkan kondisi pasien ke DPJP</p>
                                        <p><span class="font-weight-semibold">S</span>: {{v.auldd_subjektif||"-"}} <span v-if="v.auldd_riwayat_penyakit">, Pasien mempunyai riwayat penyakit: {{v.auldd_riwayat_penyakit||"-"}} </span></p>
                                        <p><span class="font-weight-semibold">B</span>: Riwayat Alergi:
                                            <ul class="mb-0">
                                                <li v-for="(v1,k1) in (v.auldd_riwayat_alergi||[])" :key="k1"><span class="font-weight-semibold">({{getConfigDynamic(Config.mr.jenisAlergi,v1.jenis)}})</span>{{v1.name||"-"}}</li>
                                            </ul>
                                        </p>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Pengobatan yang dilakukan di UGD</span>
                                            <div>{{v.auldd_catatan_dokter||"-"}}</div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Riwayat Pembedahan</span>
                                            <div>
                                            <span>{{v.auldd_laporan_dpjp_riwayat_pembedahan||"-"}}</span>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tindakan Invasif</span>
                                            <template v-if="(v.auldd_laporan_dpjp_tindakan_invasif||[]).length">
                                            <ol class="mb-0"
                                            v-for="(v,k) in (v.auldd_laporan_dpjp_tindakan_invasif||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">A: </span>Keluhan saat ini
                                            <div><span>{{v.auldd_laporan_dpjp_keluhan||"-"}}</span></div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Skor Nyeri</span>
                                            <div>{{v.auldd_hasil_skoring_nyeri}} | 
                                            
                                                <span v-if="v.auldd_hasil_skoring_nyeri == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                                                <span v-else-if="v.auldd_hasil_skoring_nyeri >= 1 && v.auldd_hasil_skoring_nyeri < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                                                <span v-else-if="v.auldd_hasil_skoring_nyeri >= 4 && v.auldd_hasil_skoring_nyeri < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                                                <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                                                
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Tanda-tanda Vital</span>
                                            <ul class="mb-0">
                                            
                                            <li>Tekanan Darah: {{v.auldd_ttv_tekanan_darah_min||0}}/{{v.auldd_ttv_tekanan_darah_max||0}} mmHG</li>
                                            <li>Nadi: {{v.auldd_ttv_nadi||0}} x/mnt - {{v.auldd_ttv_label||"-"}}</li>
                                            <li>Gula Darah: {{v.auldd_ttv_gula_darah||0}} mg/dL</li>
                                            <li>Pernafasan: {{v.auldd_ttv_pernafasan||0}} x/mnt</li>
                                            <li>SPO2: {{v.auldd_ttv_spo2||0}}%</li>
                                            <li>Suhu: {{v.auldd_ttv_suhu||0}}C</li>
                                            <li>Berat Badan {{v.auldd_ttv_weight||0}} kg</li>
                                            <li>Tinggi: {{v.auldd_ttv_height||0}} cm</li>
                                            <li>Lingkar Kepala: {{v.auldd_ttv_lingkar_kepala||0}} cm</li>
                                            <li>Luas Permukaan Tubuh Anak: {{v.auldd_ttv_luas_permukaan_anak||0}} M<sup>2</sup></li>
                                            <li>Kesadaran: Hanya merespons jika dirangsang nyeri/pain: 
                                            {{v.auldd_hasil_skoring_nyeri||0}}</li>

                                            </ul>
                                        </div>
                                        <div class="mb-2">
                                            <span>{{v.auldd_hasil_kesadaran}} | 
                                        
                                                <span v-if="v.auldd_hasil_kesadaran >= 14"> Composmentis</span> 
                                                <span v-else-if="v.auldd_hasil_kesadaran >= 12 && v.auldd_hasil_kesadaran <= 13">Apatis</span>
                                                <span v-else-if="v.auldd_hasil_kesadaran >= 10 && v.auldd_hasil_kesadaran <= 11">Delirium</span>
                                                <span v-else-if="v.auldd_hasil_kesadaran >= 7 && v.auldd_hasil_kesadaran <= 9">Somonolen</span>
                                                <span v-else-if="v.auldd_hasil_kesadaran >= 5 && v.auldd_hasil_kesadaran <= 6">Sopor</span>
                                                <span v-else-if="v.auldd_hasil_kesadaran >= 4 && v.auldd_hasil_kesadaran <= 4">Semi-Comma</span>
                                                <span v-else>Coma</span>
                                                
                                            </span>
                                            
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Lab</span>
                                            <template v-if="(v.auldd_hasil_lab||[]).length">
                                            <ol class="mb-0" v-for="(v3,k3) in (v.auldd_hasil_lab||[])" :key="k3">
                                                <li>{{v3.value}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">Hasil Radiologi</span>
                                            <template v-if="(v.auldd_hasil_radiologi||[]).length">
                                            <ol class="mb-0" v-for="(v4,k4) in (v.auldd_hasil_radiologi||[])" :key="k4">
                                                <li>{{v4.value}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                        </div>
                                        <div class="mb-2">
                                            <span class="font-weight-semibold">R</span>
                                            <div class="mb-1">
                                            <span>Rekomendasi Intervensi yang Perlu Dilanjutkan</span>
                                            <template v-if="(v.auldd_laporan_dpjp_rekomendasi_intervensi||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_rekomendasi_intervensi||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>
                                            

                                            </div>
                                            <div class="mb-1">
                                            <span>Usulan kolaborasi</span>
                                            <template v-if="(v.auldd_laporan_dpjp_usulan_kolaborasi||[]).length">
                                            <ol class="mb-0" v-for="(v,k) in (v.auldd_laporan_dpjp_usulan_kolaborasi||[])" :key="k">
                                                <li>{{v}}</li>
                                            </ol>
                                            </template>
                                            <span v-else>Tidak ada Tindakan</span>

                                            </div>
                                        </div>
                                        </div>
                                    </td>
                                    
                                    <td>
                                        <span>{{v.auldd_laporan_dpjp_tanggal_evaluasi}} {{v.auldd_laporan_dpjp_waktu_evaluasi}}</span>
                                    </td>
                                    
                                    <td>
                                        <div class="form-group">
                                        <span>{{v.auldd_laporan_dpjp_evaluasi||"-"}}</span>
                                        </div>
                                        <span>Data sudah diverifikasi oleh 
                                        {{getConfigDynamic(masterKajianDokter.mDokterAll,v.auldd_laporan_dpjp_nama)}} pada 
                                        {{v.auldd_laporan_dpjp_evaluasi_date | moment("DD MMMM YYYY")}} {{v.auldd_laporan_dpjp_evaluasi_waktu}}</span>
                                    </td>
                                    
                                    <td>
                                        <span>{{v.auldd_laporan_dpjp_ppa||"-"}}</span>
                                    </td>
                                </tr>
                                </template>
                                
                                <tr v-for="(v,k) in (dataKajianDokter.catatanAsuhanDokter||[])" :key="k">
                                    <td>
                                        <span>{{v.aucad_tanggal|moment("DD MMMM YYYY")}} 
                                        {{v.aucad_jam||"-"}}</span>
                                    </td>
                                    <td>
                                        {{v.aucad_assesmen||'-'}}
                                    </td>
                                    <td>
                                        <span>{{v.aucad_tanggal_evaluasi|moment("DD MMMM YYYY")}} 
                                        {{v.aucad_jam_evaluasi||"-"}}</span>
                                    </td>
                                    <td>
                                        {{v.aucad_evaluasi||"-"}}
                                    </td>
                                    <td>
                                        <p>{{v.aucad_ppa||"-"}}</p>
                                        <p>{{v.aucad_done_at||"-"}}</p>
                                    </td>
                                </tr>

                                <tr v-if="dataKajianDokter.row.aupd_laporan_dpjp !== 'Y' && 
                                !(dataKajianDokter.catatanAsuhanDokter||[]).length">
                                    <td colspan="99" class="text-center">Tidak Ada Data Kajian</td>                        
                                </tr>

                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </template>
                <template v-else>
                    <span class="text-center">Tidak Ada Kajian</span>
                </template>
            </div>
            <div :class="activeTab == 3 ? 'tab-pane fade show active':'tab-pane'" id="lab">
                <template v-if="Object.keys(dataKajianLab||{}).length">
                    <div class="row">
                        <div class="col-md-6 mb-2">
                        <label for="">Data Kajian</label>
                        <v-select @input="changeKajianLab($event)" placeholder="Pilih Data Kajian" v-model="dataKajianLab.row.aurm_id" :options="historiKajianLab" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                    </div> 
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered">
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{dataKajianLab.row.ap_code||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{dataKajianLab.row.ap_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Tanggal Lahir</h4>
                                    <p>{{dataKajianLab.row.ap_dob | moment("DD MMM YYYY")}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{dataKajianLab.row.cg_label||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <table class="table table-bordered mb-3">
                        <thead>
                        <tr class="table-light">
                            <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colspan="2">
                            <div class="result_tab">
                            <h4>Prioritas Pemeriksaan</h4>
                            <p v-if="dataKajianLab.dataDokter.aupdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                            <p v-else>Non CITO</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="table-responsive mb-3">
                        <table class="table table-bordered">
                        <tbody>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Tanggal Pemeriksaan</h4>
                                <p v-if="dataKajianLab.rowReg.aur_is_lab_at">{{dataKajianLab.rowReg.aur_is_lab_at|moment("DD MMM YYYY")}}</p>
                                <p v-else> - </p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Apakah Pasien Berpuasa?</h4>
                                <p>{{dataKajianLab.dataDokter.aupdl_fasting == 'Y' ? 'Ya':'Tidak'}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <template v-for="(v,k) in dataKajianLab.dataDokter.aupdl_data">
                            <div class="col-md-6 col-lg-6" :key="k+'labData'" v-if="isShowHeadLabV2(k)">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                </div>
                                <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.dubData||[])" >
                                    <div class="req-list" v-if="isShowHeadSubHeadLabV2(k,k1)" :key="k1+'labdatas'">
                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                        <template v-for="v2,k2 in (v1.data||[])">
                                        <div v-if="isShowLabV2(k,k1,k2)" :key="k2+'labsubdatas'">
                                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                            <span v-if="v2.notes">, {{v2.notes}}</span>
                                        </div>
                                        </template>
                                    </div>
                                    </template>
                                </div>
                                </div>
                            </div>
                            </template>

                            <div class="col-md-6 col-lg-6" v-if="dataKajianLab.dataDokter.aupdl_hasil_lainnya">
                            <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                        <div>
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle font-weight-semibold">{{dataKajianLab.dataDokter.aupdl_hasil_lainnya||"-"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <span v-if="!selectedLabInputV2()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="card">
                            <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped table-sm patient-table">
                                    <thead>
                                    <tr>
                                        <th>Hasil Pemeriksaan </th>
                                        <th> Hasil</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v,k) in (dataKajianLab.row.auppl_hasil||[])" :key="k+'hasil'">
                                        <td>{{v.value}}<br/>
                                        <small class="text-success" v-if="dataKajianLab.rowReg.aur_is_verified_lab == 'Y'">
                                        Verified</small>
                                        <small class="text-danger" v-else>
                                        Unverified</small>
                                        </td>
                                        <td>
                                            <div class="result_tab form-group mb-0">
                                            <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="!(dataKajianLab.row.auppl_hasil||[]).length">
                                        <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <span class="text-center">Tidak Ada Kajian</span>
                </template>
            </div>
            <div :class="activeTab == 4 ? 'tab-pane fade show active':'tab-pane'" id="radio">
                <template v-if="Object.keys(dataKajianRadiologi||{}).length">
                
                    <div class="row">
                        <div class="col-md-6 mb-2">
                        <label for="">Data Kajian</label>
                        <v-select @input="changeKajianRadiologi($event)" placeholder="Pilih Data Kajian" v-model="dataKajianRadiologi.row.aurm_id" :options="historiKajianRadiologi" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                    </div> 
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered">
                            <tbody>
                            <tr>
                                 <td>
                                <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{dataKajianRadiologi.row.ap_code||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{dataKajianRadiologi.row.ap_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Tanggal Lahir</h4>
                                    <p>{{dataKajianRadiologi.row.ap_dob | moment("DD MMM YYYY")}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{dataKajianRadiologi.row.cg_label||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <table class="table table-bordered mb-3">
                        <thead>
                        <tr class="table-light">
                            <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colspan="2">
                            <div class="result_tab">
                                <h4>Prioritas Pemeriksaan</h4>
                                <p v-if="dataKajianRadiologi.dataDokter.aupdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                <p v-else>Non CITO</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                                <p>{{dataKajianRadiologi.dataDokter.aupdr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div class="table-responsive mb-3">
                        <table class="table table-bordered">
                        <tbody>
                            <tr>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Waktu Pelaksanaan</h4>
                                <p v-if="dataKajianRadiologi.rowReg.aur_is_radiologi_at">{{dataKajianRadiologi.rowReg.aur_is_radiologi_at|moment("DD MMM YYYY")}}</p>
                                <p v-else> - </p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div class="row">
                            <template v-for="(v,k) in dataKajianRadiologi.dataDokter.aupdr_data">
                            <div class="col-md-6 col-lg-6" :key="k+'radioData'" v-if="isShowHeadRadiologiV2(k)">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{
                                    getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                </div>
                                <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.data||[])">
                                    <div v-if="isShowRadiologiV2(k,k1)" :key="k1+'radiosubdatas'">
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                                        <span v-if="v1.notes">, {{v1.notes}}</span>
                                        
                                        <a href="javascript:;" @click="openInformConsentDetailRadiologi(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>

                                        <a href="javascript:;" @click="openInformConsentDetailRadiologi2(v1,k1,k,getConfigDynamic(Config.mr.KategoriRadiologi,v.head))" v-if="v1.inform_consent_radiologi" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                        <i class="icon-file-eye2"></i>
                                        </a>
                                    </div>
                                    </template>
                                </div>
                                </div>
                            </div>
                            </template>
                            <div class="col-md-6 col-lg-6" v-if="dataKajianRadiologi.dataDokter.aupdr_hasil_lainnya">
                            <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                        <div>
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle font-weight-semibold">{{dataKajianRadiologi.dataDokter.aupdr_hasil_lainnya||"-"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <span v-if="!selectedRadioInputV2()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <div class="card">
                            <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered table-striped table-sm patient-table">
                                    <thead>
                                    <tr>
                                        <th>Hasil Pemeriksaan </th>
                                        <th> Hasil</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(v,k) in (dataKajianRadiologi.row.auppr_hasil||[])" :key="k+'hasil'">
                                        <td>{{v.value}}<br/>
                                        </td>
                                        <td>
                                            <div class="result_tab form-group mb-0">
                                            <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="!(dataKajianRadiologi.row.auppr_hasil||[]).length">
                                        <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <span class="text-center">Tidak Ada Kajian</span>
                </template>

            </div>
            <div :class="activeTab == 5 ? 'tab-pane fade show active':'tab-pane'" id="riwayatFarmasi">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Resep</h6>
                    </div>
                    <div class="card-header pb-0">
                    <ul class="nav nav-tabs nav-tabs-bottom nav-justified mb-0">
                        <li class="nav-item"><a href="javascript:;" @click="changeTabFarmasi(1)" 
                            :class="activeTabFarmasi == 1 ? 'nav-link active':'nav-link'">Resep dari
                            Farmasi</a>
                        </li>
                        <li class="nav-item"><a href="javascript:;" @click="changeTabFarmasi(2)" 
                            :class="activeTabFarmasi == 2 ? 'nav-link active':'nav-link'">Resep dari Dokter</a>
                        </li>
                    </ul>
                    </div>
                    <div class="tab-content">
                        <div :class="activeTabFarmasi == 1 ? 'tab-pane fade show active':'tab-pane'" id="recipeFarmasi">
                            <div class="card-body">
                            <table class="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <th>Jenis</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Dosis</th>
                                    <th>Keterangan</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(v,k) in (dataKajianFarmasi||[])" :key="'resepDokter'+k">
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurdf_jenis == 1 ? 'Obat' : v.aurdf_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p v-if="v.aurdf_item_id == 99999">
                                        {{v.aurdf_item_lainnya||"-"}}
                                        </p>
                                        <p v-else>
                                        {{v.aurdf_nama||"-"}}
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurdf_jumlah||"-"}} {{v.aurdf_satuan||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>
                                            <span v-if="v.aurdf_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                            <span v-else>{{v.aurdf_frekuensi_lainnya || "-"}}</span>
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurdf_keterangan || "-"}}</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="!(dataKajianFarmasi||[]).length" class="table-info">
                                    <td class="text-center" colspan="99">Tidak Ada Data</td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div :class="activeTabFarmasi == 2 ? 'tab-pane fade show active':'tab-pane'" id="recipeDoctor">
                            <div class="card-body">
                            <table class="table table-bordered table-sm">
                                <thead>
                                <tr>
                                    <th>Jenis</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Dosis</th>
                                    <th>Keterangan</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(v,k) in (dataKajianDokter.resepDokter||[])" :key="'resepDokter'+k">
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_jenis == 1 ? 'Obat' : v.aurd_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p v-if="v.aurd_item_id == 99999">
                                        {{v.aurd_item_lainnya||"-"}}
                                        </p>
                                        <p v-else>
                                        {{v.aurd_nama||"-"}}
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_jumlah||"-"}} {{v.aurd_satuan||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>
                                        <span v-if="v.aurd_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                        <span v-else>{{v.aurd_frekuensi_lainnya || "-"}}</span>
                                        </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <p>{{v.aurd_keterangan || "-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr v-if="!(dataKajianDokter.resepDokter||[]).length" class="table-info">
                                    <td class="text-center" colspan="99">Tidak Ada Data</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="activeTab == 6 ? 'tab-pane fade show active':'tab-pane'" id="persetujuanPasien">
                <ul class="nav nav-tabs nav-justified nav-tabs-bottom">
                    <li class="nav-item"><a href="javascript:;" @click="changeTabPS(1)" 
                            :class="activeTabPS == 1 ? 'nav-link active':'nav-link'">Persetujuan Pasien</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTabPS(2)" 
                            :class="activeTabPS == 2 ? 'nav-link active':'nav-link'">General Consent</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTabPS(3)" 
                            :class="activeTabPS == 3 ? 'nav-link active':'nav-link'">Informasi Pasien</a></li>
                </ul>
                <div class="tab-content">
                    <div :class="activeTabPS == 1 ? 'tab-pane fade show active':'tab-pane'" id="perPasienTunjang">
                        <table class="table table-bordered table-sm text-uppercase mb-3">
                            <thead>
                            <tr>
                                <th colspan="3">Persetujuan Pasien</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Informasi Ketentuan Pembayaran</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Kebutuhan Penerjemah Bahasa</h4>
                                    <p>Tidak</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Hasil Pemeriksaan Penunjang Dapat diakses oleh Peserta Didik</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Informasi tentang Hak dan Kewajiban</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Pelepasan Informasi / Kerahasiaan Informasi</h4>
                                    <p>Ya</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Anggota Keluarga Lain yang dapat diberikan Informasi Data-Data Pasien</h4>
                                    <p>Yusuf Sumanto, Indra Indraja, Wisnu Bakti</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Informasi Tentang Tata Tertib RS</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Hasil Pemeriksaan Penunjang Dapat Diberikan kepada Pihak Penjamin</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Fasyankes Tertentu dalam Rangka Rujukan</h4>
                                    <p>Setuju</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered table-sm text-uppercase">
                            <thead>
                            <tr>
                                <th colspan="3">Yang Membuat Pernyataan</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Penanggung Jawab,</h4>
                                    <p>Nanda Setiawan</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Petugas yang Memberi Penjelasan</h4>
                                    <p>Indita Widayanti</p>
                                </div>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div :class="activeTabPS == 2 ? 'tab-pane fade show active':'tab-pane'" id="perPasienGenConsent">
                        <table class="table table-bordered table-sm">
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Persetujuan Dilakukan Oleh</h4>
                                    <p>{{dataUGDReservasi.aures_pu_pic == 'P' ? 'Pasien' : 'Wali hukum'}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Wewenang Pelepasan Informasi Kepada</h4>
                                    <p>{{wewenang||"-"}}</p>
                                </div>
                                </td>
                                <!--
                                <td>
                                <div class="result_tab">
                                    <h4>Partisipasi Mahasiswa dalam perawatan pasien</h4>
                                    <p>{{dataUGDReservasi.aures_pu_info_ranap == 'Y' ? 'Setuju' : 'Tidak Setuju'}}</p>
                                </div>
                                </td>
                                -->
                            </tr>
                            <!--
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>Akses Menengok Pasien</h4>
                                    <p>{{dataUGDReservasi.aures_pu_privasi == 'Y' ? 'Setuju' : 'Tidak Setuju'}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama yang tidak diizinkan </h4>
                                    <p>{{dataUGDReservasi.aures_pu_name || "-"}}</p>
                                </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                            -->
                            </tbody>
                        </table>
                    </div>
                    <div :class="activeTabPS == 3 ? 'tab-pane fade show active':'tab-pane'" id="infoPasienTunjang">
                        <table class="table table-bordered table-sm text-uppercase">
                        <tbody>
                            <tr>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{row.ap_code||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>Nama</h4>
                                <p>{{row.ap_fullname||"-"}}</p>
                                </div>
                            </td>
                            <td width="33%">
                                <div class="result_tab">
                                <h4>NIK</h4>
                                <p>{{row.ap_nik}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Tempat, Tanggal Lahir</h4>
                                <p>{{row.ap_pob||"-"}}, {{row.ap_dob | moment("DD MMM YYYY")}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Usia</h4>
                                <p>{{row.ap_usia_with_ket||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Kategori Usia</h4>
                                <p>{{row.ap_gol_usia||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{row.cg_label||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Agama</h4>
                                <p>{{row.ma_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Suku</h4>
                                <p>{{row.ap_suku||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Pekerjaan</h4>
                                <p>{{row.mp_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Pendidikan</h4>
                                <p>{{row.mpen_name||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Nama Ibu Kandung</h4>
                                <p>{{row.ap_nama_ibu||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>No. Telpon</h4>
                                <p>{{row.ap_phone_number||"-"}}</p>
                                </div>
                            </td>
                            <td colspan="2">
                                <div class="result_tab">
                                <h4>Email</h4>
                                <p v-if="row.ap_email">{{row.ap_email.trim()||"-"}}</p>
                                <p v-else> - </p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Status Pernikahan</h4>
                                <p v-if="row.ap_status_pernikahan == 1">Belum Kawin</p>
                                <p v-else-if="row.ap_status_pernikahan == 2">Kawin</p>
                                <p v-else-if="row.ap_status_pernikahan == 3">Cerai Hidup</p>
                                <p v-else-if="row.ap_status_pernikahan == 4">Cerai Mati</p>
                                <p v-else> - </p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Bahasa yang Dikuasai</h4>
                                <p>{{row.ap_language||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Golongan Darah</h4>
                                <p v-if="row.ap_gol_darah">{{row.ap_gol_darah.trim()||"-"}}</p>
                                <p v-else> - </p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td colspan="3">
                                <div class="result_tab">
                                <h4>Alamat Domisili</h4>
                                <p>{{domisiliAddr||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                            <tr>
                            <td colspan="3">
                                <div class="result_tab">
                                <h4>Alamat Sesuai KTP</h4>
                                <p>{{ktpAddr||"-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        
        
        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan / ICD-9-CM</h4>
                        <p>{{rowIC.auic_name}}</p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.auic_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.auic_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.auic_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.auic_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.auic_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.auic_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.auic_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.auic_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.auic_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.auic_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.auic_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    data(){
        return {
            activeTab: 1,
            activeTabDokter: 1,
            activeTabFarmasi: 1,
            activeTabPS: 1,
            keadaanUmum: false,
            activeAnat: 0,    
            rowIC: {},
            openModalICDetail: false,
            kajianTriase: null
        }
    },
    props:{
        row:Object,
        rowReg:Object,
        Config: Object,
        dataKajianDokter: Object,
        dataKajianTriase: Object,        
        historiKajianDokter: Array,
        masterKajianDokter: Object,
        masterTriase: Object,

        historiKajianPerawat: Array,

        historiKajianRadiologi: Array,
        dataKajianRadiologi: Object,
        historiKajianLab: Array,
        dataKajianLab: Object,

        historiKajianTriase: Array,

        dataKajianFarmasi: Array,
        dataUGDReservasi: Object


    },
    
    mounted(){
        setTimeout(()=>{
            this.remountedIC()
        },2000)
    },
    methods: { 
        changeKajianTriase(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            let data = {
                id: e,
                regId: this.rowReg.aur_id,
                type: 'get-log'
            }
            Gen.apiRest(
                "/do/" + 'UGDTriase', {
                    data: data
                },
            "POST"
            ).then(res => {
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataKajianTriase = res.data
                this.$parent.$parent.$parent.dataKajianTriase.Config = this.Config
            })
        },
        changeKajianDokter(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'UGDFormDokter'+'/'+this.dataKajianDokter.row.ap_id+'/'+e+'?regId='+this.rowReg.aur_id, 
            ).then(res=>{
                console.log(res.data)
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataKajianDokter = res.data
                this.$parent.$parent.$parent.dataKajianDokter.Config = this.Config
            })
        },
        changeKajianRadiologi(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'UGDFormRadiologi'+'/'+this.dataKajianRadiologi.row.ap_id+'/'+e+'?regId='+this.rowReg.aur_id, 
            ).then(res=>{
                console.log(res.data)
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataKajianRadiologi = res.data
                this.$parent.$parent.$parent.dataKajianRadiologi.Config = this.Config
            })
        },
        changeKajianLab(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'UGDFormLab'+'/'+this.dataKajianLab.row.ap_id+'/'+e+'?regId='+this.rowReg.aur_id, 
            ).then(res=>{
                console.log(res.data)
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataKajianLab = res.data
                this.$parent.$parent.$parent.dataKajianLab.Config = this.Config
            })
        },

        remountedIC(){
            for(let i = 0; i < (this.dataKajianDokter.row.aupdr_data||[]).length; i++){
                for(let j = 0; j < (this.dataKajianDokter.row.aupdr_data[i]['data']||[]).length; j++){  
                    if(this.dataKajianDokter.row.aupdr_data[i]['data'][j]['selected']){
                        Gen.apiRest('/do/' + 'UGDFormRadiologi', {
                            data: {
                                type: 'check-data-ic',
                                id: this.dataKajianDokter.row.aupdr_data[i]['data'][j]['id'],
                            }
                        }, 'POST').then(res => {
                            this.dataKajianDokter.row.aupdr_data[i]['data'][j]['inform_consent_radiologi'] = res.data
                        })
               
                    }
                }
            }

            if(Object.keys(this.dataKajianRadiologi||{}).length){
                for(let i = 0; i < (this.dataKajianRadiologi.dataDokter.aupdr_data||[]).length; i++){
                    for(let j = 0; j < (this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data']||[]).length; j++){  
                        if(this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data'][j]['selected']){
                            Gen.apiRest('/do/' + 'UGDFormRadiologi', {
                                data: {
                                    type: 'check-data-ic',
                                    id: this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data'][j]['id'],
                                }
                            }, 'POST').then(res => {
                                this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data'][j]['inform_consent_radiologi'] = res.data
                            })
                
                        }
                    }
                }
            }
            
        },

        openInformConsentDetailRadiologi(v,k1,k,kat){
            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'get-data-ic',
                    id: v.inform_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text  + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent
                 
                this.rowIC.k1 = k1
                this.rowIC.k = k

                this.openModalICDetail = true                   
            })
        }, 
        openInformConsentDetailRadiologi2(v,k1,k,kat){
            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'get-data-ic',
                    id: v.inform_consent_radiologi,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.tindakan = v.text  + "("+kat+")"
                this.rowIC.id_tindakan = v.inform_consent_radiologi
                 
                this.rowIC.k1 = k1
                this.rowIC.k = k

                this.openModalICDetail = true                   
            })
        }, 

        openInformConsentDetail(v){
            console.log(v)
            Gen.apiRest('/do/' + 'UGDFormDokter', {
                data: {
                    type: 'get-data-ic',
                    id: v.auicd9d_informed_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = v.mi9_name
                this.rowIC.mi9_code = v.mi9_code
                this.rowIC.id_icd = v.auicd9d_id
                this.openModalICDetail = true                   
            })
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        changeTab(k){
            this.activeTab = k
        },
        changeTabDokter(k){
            this.activeTabDokter = k
        },
        changeTabFarmasi(k){
            this.activeTabFarmasi = k
        },
        changeTabPS(k){
            this.activeTabPS = k
        },
        changeTabAnat(k){
            this.activeAnat = k
        },

        
        isShowHeadLab(i){
          let isData = 0
          for(let j = 0; j < (this.dataKajianDokter.row.aupdl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.dataKajianDokter.row.aupdl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.dataKajianDokter.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLab(i,j){
          let isData = 0
          for(let k = 0; k < (this.dataKajianDokter.row.aupdl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.dataKajianDokter.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLab(i,j,k){
          let isData = 0
          if(this.dataKajianDokter.row.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },

        isShowHeadRadiologi(j){
          let isData = 0
          for(let k = 0; k < (this.dataKajianDokter.row.aupdr_data[j]['data']||[]).length; k++){  
            if(this.dataKajianDokter.row.aupdr_data[j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowRadiologi(j,k){
          let isData = 0
          if(this.dataKajianDokter.row.aupdr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        
        selectedLabInput(){
          let data = []
          for(let ik = 0; ik < (this.dataKajianDokter.row.aupdl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.dataKajianDokter.row.aupdl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.dataKajianDokter.row.aupdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.dataKajianDokter.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.dataKajianDokter.row.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.dataKajianDokter.row.aupdl_hasil_lainnya){
            data.push(this.dataKajianDokter.row.aupdl_hasil_lainnya)
          }
          return data.join(", ")
        },
       
        selectedRadioInput(){
          let data = []
          for(let i = 0; i < (this.dataKajianDokter.row.aupdr_data||[]).length; i++){
            for(let j = 0; j < (this.dataKajianDokter.row.aupdr_data[i]['data']||[]).length; j++){  
                if(this.dataKajianDokter.row.aupdr_data[i]['data'][j]['selected']){
                  data.push(this.dataKajianDokter.row.aupdr_data[i]['data'][j]['text'])
                }
            }
          }
          if(this.dataKajianDokter.row.aupdr_hasil_lainnya){
            data.push(this.dataKajianDokter.row.aupdr_hasil_lainnya)
          }
          return data.join(", ")
        },




        
        isShowHeadLabV2(i){
          let isData = 0
          for(let j = 0; j < (this.dataKajianLab.dataDokter.aupdl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.dataKajianLab.dataDokter.aupdl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.dataKajianLab.dataDokter.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLabV2(i,j){
          let isData = 0
          for(let k = 0; k < (this.dataKajianLab.dataDokter.aupdl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.dataKajianLab.dataDokter.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLabV2(i,j,k){
          let isData = 0
          if(this.dataKajianLab.dataDokter.aupdl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },

        isShowHeadRadiologiV2(j){
          let isData = 0
          for(let k = 0; k < (this.dataKajianRadiologi.dataDokter.aupdr_data[j]['data']||[]).length; k++){  
            if(this.dataKajianRadiologi.dataDokter.aupdr_data[j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowRadiologiV2(j,k){
          let isData = 0
          if(this.dataKajianRadiologi.dataDokter.aupdr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        
        selectedLabInputV2(){
          let data = []
          for(let ik = 0; ik < (this.dataKajianLab.dataDokter.aupdl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.dataKajianLab.dataDokter.aupdl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.dataKajianLab.dataDokter.aupdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.dataKajianLab.dataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.dataKajianLab.dataDokter.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.dataKajianLab.dataDokter.aupdl_hasil_lainnya){
            data.push(this.dataKajianLab.dataDokter.aupdl_hasil_lainnya)
          }
          return data.join(", ")
        },
       
        selectedRadioInputV2(){
          let data = []
          for(let i = 0; i < (this.dataKajianRadiologi.dataDokter.aupdr_data||[]).length; i++){
            for(let j = 0; j < (this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data']||[]).length; j++){  
                if(this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data'][j]['selected']){
                  data.push(this.dataKajianRadiologi.dataDokter.aupdr_data[i]['data'][j]['text'])
                }
            }
          }
          if(this.dataKajianRadiologi.dataDokter.aupdr_hasil_lainnya){
            data.push(this.dataKajianRadiologi.dataDokter.aupdr_hasil_lainnya)
          }
          return data.join(", ")
        }
    },

    computed: {
        wewenang(){
            let datawewenang = []
            for(let i = 0; i < (this.dataUGDReservasi.aures_penerima_info||[]).length; i++){
                datawewenang.push(
                    this.dataUGDReservasi.aures_penerima_info[i].nama + " (" +this.dataUGDReservasi.aures_penerima_info[i].hub +"," + this.dataUGDReservasi.aures_penerima_info[i].tel + ")"
                )
            }
            return (datawewenang).join(", ")
        },
        airwaysVal(){
            let value = []
            if(this.dataKajianDokter.row.auod_airways_pangkal_lidah == "Y"){
                value.push("Pangkal Lidah Jatuh")
            }
            if(this.dataKajianDokter.row.auod_airways_sputum == "Y"){
                value.push("Sputum")
            }
            if(this.dataKajianDokter.row.auod_airways_sputum == "Y"){
                value.push("Darah")
            }
            if(this.dataKajianDokter.row.auod_airways_sputum == "Y"){
                value.push("Spasme")
            }
            if(this.dataKajianDokter.row.auod_airways_sputum == "Y"){
                value.push("Benda Asing") 
            }
            if(!value.length){
                return "-"
            }else{
                return value.join(", ")
            }
        },
        circulationVal(){
            let value = []
            if(this.dataKajianDokter.row.auod_circulation_akral_dingin == "Y"){
                value.push("Akral Dingin")
            }
            if(this.dataKajianDokter.row.auod_circulation_kulit_kering == "Y"){
                value.push("Kulit Kering")
            }
            if(this.dataKajianDokter.row.auod_circulation_pendarahan == "Y"){
                value.push("Pendarahan")
            }
            if(this.dataKajianDokter.row.auod_circulation_pengisian_kapiler == "Y"){
                value.push("Pengisian Kapiler")
            }
            if(this.dataKajianDokter.row.auod_circulation_nadi == "Y"){
                value.push("Nadi") 
            }
            if(this.dataKajianDokter.row.auod_circulation_pucat == "Y"){
                value.push("Pucat") 
            }
            if(this.dataKajianDokter.row.auod_circulation_sianosis == "Y"){
                value.push("Sianosis") 
            }
            if(this.dataKajianDokter.row.auod_circulation_turgor_kulit_kurang == "Y"){
                value.push("Turgor Kulit Kurang") 
            }
            if(this.dataKajianDokter.row.auod_circulation_lainnya == "Y"){
                value.push("Sirkulasi Lainnya") 
            }
            if(!value.length){
                return "-"
            }else{
                return value.join(", ")
            }
        },
        ktpAddr(){
            if(this.row.ap_address && this.row.ap_rt && this.row.ap_rw && this.row.ap_kelurahan && this.row.ap_kecamatan && this.row.ap_kota){
            return (this.row.ap_address||"-") + "RT."+(this.row.ap_rt||"-")+"/"+(this.row.ap_rw||"-")+", DESA "+
            (this.row.ap_kelurahan||"-")+", KEC."+(this.row.ap_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_kota||"-")
            }else{
            return "-"
            }
        },
        domisiliAddr(){
            if(this.row.ap_dom_address && this.row.ap_dom_rt && this.row.ap_dom_rw && this.row.ap_dom_kelurahan && this.row.ap_dom_kecamatan && this.row.ap_dom_kota){
            return (this.row.ap_dom_address||"-") + "RT."+(this.row.ap_dom_rt||"-")+"/"+(this.row.ap_dom_rw||"-")+", DESA "+
            (this.row.ap_dom_kelurahan||"-")+", KEC."+(this.row.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_dom_kota||"-")
            }else{
            return "-"
            }
        },
    },  

    
}

</script>